import * as React from "react";
import AvoidBreach from "../components/CTA/AvoidBreach";
import Confident from "../components/CTA/Confident";
import Divider from "../components/divider";
import Footer from "../components/footer";
import Landing from "../components/landing";
import Layout from "../components/layout";
import Auditing from "../components/sections/auditing";
import Contact from "../components/sections/contact";
import Methodology from "../components/sections/methodology";
import Pentesting from "../components/sections/pentesting";

const SecurityPage = () => {
  return (
    <Layout>
      <Landing
        imgUrl="landing_map.jpg"
      />
      <Auditing />
      <Confident />
      <Pentesting />
      <AvoidBreach />
      {/* <Methodology />
      <Divider /> */}
      <Contact />
      <Footer />
    </Layout>
  );
};

export default SecurityPage;
